.App {

}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.recharts-surface:nth-child(2){
  display: none !important;
}
.hiddenChart>svg{
  display: none;
}
.recharts-wrapper {
  height: unset !important;
}
.recharts-legend-wrapper {
  margin-top: 1rem;
  position: relative !important;
  height: unset !important;
}
.recharts-legend-item>svg{
  display: inline-block !important;
}


.recharts-legend-item-text{
  font-size: 10px;
  font-weight: 300;
  color: #292929;
}

@media (min-width: 758px) {
  .recharts-legend-item-text{
    font-size: 12px;
    color: #292929;
  }
}

