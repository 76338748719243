@import "../../styles/colors";
@import "../../styles/typography";
@import "../../styles/responsive";

.gridGlobalContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  overflow: hidden;
  padding-bottom: 1em; }

.parentContainer {
  display: block;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  //height: 100%
  padding-top: 7em;
 }  //overflow: hidden !important

.tileInfoBar {
  width: -webkit-fill-available !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
  border-left: 7px solid #03A9F4 !important;
  border-top-right-radius: 20px !important;
  background-color: rgba(218, 218, 218, 0.93) !important;
  span {
    color: #060606;
    font-weight: 400; } }

.restaurantInfoContent {
  display: flex;
  justify-content: center;
  align-items: center; }


.restaurantLogoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1em !important; }


.restaurantLogo {
  height: 80px !important;
  width: 80px !important;
  margin-right: 1em !important;
  border-radius: 50px !important;

  display: inline-block !important;
  vertical-align: middle !important; }


.restaurantName {
  text-align: center; }

.content {
  padding-left: 2em !important;
  padding-right: 2em !important; }

.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 25em !important;
  border-radius: 15px !important;
  padding-top: 2em !important;
  background-color: $complementary-sand-color !important; }


.chargingContent {
  display: flex;
  justify-content: center; }

.menuSelectorButton {
  position: fixed;
  z-index: 10000;
  top: 0.2em;
  right: 0.2em; }


.menuSelectorTitle {
  margin-bottom: 1em !important;
  text-align: center !important; }

.menuSelectorCardContent {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center; }

.menuChip {
  margin: 0.7em;
  min-width: 60%;
  background-color: $primary-color !important;
  color: #fff !important;
  padding: 1.5em !important; }


.securityPinModalContent {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center; }

.pinInput {
  margin-top: 1em;

  input {
    text-align: center;
    -webkit-text-security: disc !important;
    margin: 4px;
    -moz-appearance: textfield !important;
    -webkit-appearance: none !important;
    width: 15px;
    border-radius: 5px;
    font-size: 18px;
    height: 26px;
    padding: 10px;
    color: #000;
    border: 1px solid lightskyblue; }

  input:focus {
    border: 2px solid #2787fa; }

  /* HIDE INPUT ARROWS TO FIX DESIGN */
  input::-webkit-outer-spin-button,  input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    margin: 0 !important; }

  input[type="number"] {
    -moz-appearance: textfield !important; } }
//////////////////////////////////////

.invisibleButton {
  visibility: hidden; }

.dialogContentText {
  font-size: $medium-large-font-size !important; }
