@import "../../styles/colors";
@import "../../styles/typography";
@import "../../styles/responsive";

.privacy {
  display: flex;
  flex-direction: column;
  margin-top: 80px;
  padding: 4% 20% 4% 20%;
  text-align: justify;
  @media (max-width: $tablet) {
    padding: 4% 20px 4% 20px; } }

.downloadButtons {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 2em; }

.navBar {
  background-color: $primary-color !important; }

.privacy {
  cursor: pointer; }
