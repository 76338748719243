@import "../../../../styles/colors";
@import "../../../../styles/typography";
@import "../../../../styles/responsive";

.card {}

.cardActionArea {
    display: flex !important;
    flex-direction: column !important;
    justify-content: flex-start !important;
    min-height: 24em !important; }
.cardImage {
  object-fit: cover; }
