@import "../../../../styles/colors";
@import "../../../../styles/typography";
@import "../../../../styles/responsive";

html {
  scroll-behavior: smooth; }

.parentBox {
  display: flex;
  justify-content: center;
  width: 99%; }
//height: 100%
.firstStepFather {
  display: flex;
  justify-content: center; }

.gridContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  height: 100%; }

.progressAnimation {
  display: flex;
  justify-content: center; }

.restaurantName {
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 1em 0em !important; }

.calendar {
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
  border: none !important;
  border-radius: 4px;
  padding: 0.5em;
  width: unset !important;
  align-self: flex-start; }

.sectionTitle {
  display: flex;
  align-items: center;
  @media (max-width: $mobile) {
    justify-content: center; }

  .sectionTitleIcon {
    margin-right: 1em; }

  .sectionPeopleIcon {
    margin-right: 1em;
    @media(min-width: $computer) {
      margin-left: 1em; } } }


.howManyPeopleBox {
  display: flex;
  padding: 1em;

  .howManyPeopleCard {
    width: 100%;
    justify-content: center;

    .howManyPeopleCardContent {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;

      .howManyPeopleButton {
        margin: 0.5em !important; }

      .howManyPeopleButtonActive {
        margin: 0.5em !important;
        background-color: $primary-color-hover;
        color: #fff; } }

    .howManyPeopleCardContentGroup {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-evenly;

      .howManyPeopleGroupClaim {
        @media (max-width: $tablet) {
          margin-right: 1em;
          width: 50%; } }

      .howManyPeopleGroupInput {
        @media (max-width: $tablet) {
          width: 50%; } } } } }

.calendarHoursContainer {
  //display: flex
  display: none;
  justify-content: space-between !important;
  @media (max-width: $mobile) {
    flex-direction: column;
    justify-content: center;
    align-items: center; } }

.hoursParentContainer {
  @media (max-width: $mobile) {
    margin-top: 1em !important; } }

.calendarBox {
  display: flex; }

.hoursCard {
  width: 100%;

  .divider {
    margin-top: 0.5em !important;
    margin-bottom: 0.5em !important; }

  .hoursContainer {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    .hourButton {
      margin: 0.5em !important; }

    .hourButtonActive {
      margin: 0.5em !important;
      background-color: $primary-color-hover !important;
      color: #fff !important; } } }

.secondStepContainer {
  display: none; }

.reservationResume {
  display: flex;
  flex-direction: row;
  background-color: aliceblue;
  border-left: 1px solid;
  margin-top: 1em;
  margin-left: 1em !important;
  margin-right: 1em !important;
  margin-bottom: 1em !important;
  //border-radius: 15px
  @media (max-width: $mobile) {
    margin-top: 0px !important;
    margin-bottom: 10px !important;
    flex-direction: column-reverse;
    align-items: center; }

  .reservationForm {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .resumeWrapper {

    display: flex;
    flex-direction: row;
    align-items: center;
    @media (max-width: $mobile) {
      //margin-bottom: 1rem
      width: 100%;
      display: flex;
      flex-direction: column-reverse;
      align-items: flex-start;
      padding-left: 0.75em; } }


  .reservationResumeItem {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    @media (max-width: $mobile) {
      margin: 0.25em; } }

  .reservationResumeItemIcon {
    margin-right: 0.5em; } }

.allowNotificationsBox {
  display: flex;
  margin-bottom: 1em !important;
  margin-top: 0.5em !important; }

.buttonBackIcon {
  margin-right: 0.5em; }
.backButton {
  margin-bottom: 20px !important; }
.confirmedReservation {
  display: flex;
  color: $primary-color;
  flex-direction: column;
  justify-content: center;
  padding: 4em !important;

  .confirmedReservationMessage {
    text-align: center; } }

.legalClause {
  margin-top: 0.5em !important; }
