@keyframes floatBracelet {
  0% {
    transform: translatey(0px) rotate(-10deg); }
  50% {
    transform: translatey(-20px) rotate(-10deg); }
  100% {
    transform: translatey(0px) rotate(-10deg); } }

@keyframes floatVerticalBracelet {
  0% {
    transform: translatey(0px) rotate(-90deg); }
  50% {
    transform: translatey(-20px) rotate(-90deg); }
  100% {
    transform: translatey(0px) rotate(-90deg); } }
