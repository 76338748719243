$primary-color: #008eef;
$primary-color-hover: #0083CC;
$secondary-color: #0012C0;
$secondary-color-hover: #000F98;
$third-color: #FF812C;
$third-color-hover: #FF6700;
$fourth-color: #FFBA00;
$fourth-color-hover: #DDA100;
$light-blue: #e4eff3;
$light-orange: #eee4d8;

$box-background: #f7f7f7;



$complementary-darkgrey-color: #3E3F3D;
$complementary-charcoal-color: #121c29;
$complementary-concrete-color: #716b77;
$complementary-silver-color: #dddcdc;
$complementary-soft-color: #f5f5f5;
$complementary-sand-color: #fafafa;
$complementary-white-color: #ffffff;
$complementary-black-opacity-7: rgba(0, 0, 0, 0.7);

$error-color: #ff3d00;

$snack-error: #dd2c01;
$snack-success: #4da452;
$snack-warning: #ffac00;

$facebook-pantone-color: #3b5998;
$twitter-pantone-color: #55acee;
$linkedin-pantone-color: #0077b5;
$instagram-pantone-color: #8a3ab9;
$youtube-pantone-color: #ff0000;


$gold-primary: #dcb061;
$light-gold: #debc67;
$dark-gold: #dba13e;
$darker-gold: #a5792d;
