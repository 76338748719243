@import "../../styles/colors";
@import "../../styles/typography";
@import "../../styles/responsive";

.containerText {
  display: inline;
  padding-top: 2rem;
  padding-bottom: 2rem; }
.notFound {
  background: -webkit-linear-gradient(90deg, $primary-color 10%, $secondary-color 90%);
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  height: 100vh;
  margin-top: -7vh;
  padding: 4% 10% 4% 10%;
  text-align: center; }


.h1 {
  margin: 0 !important;
  padding: 0 !important;
  color: white; }

.oops {

  margin: 0 !important;
  padding: 0 !important;
  color: $third-color; }

.button {
  background-color: $third-color !important;
  color: white !important; }

.img {
  width: 165px;
  height: 80px;
  justify-content: center;
  margin: 0 auto; }
