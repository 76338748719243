@import "../../../../styles/colors";
@import "../../../../styles/typography";
@import "../../../../styles/responsive";

.navbar {
  display: flex;
  color: white;
  flex-direction: column;
  justify-content: center;
  background-color: $complementary-darkgrey-color;
  padding: 25px;
  position: fixed;
  z-index: 10000 !important;
  width: 90%;
  @media (min-width: $tablet) {
    width: 100%;
    padding: 0; }
  .navbarContainerMobile {
    display: flex;
    justify-content: space-between;
    @media (min-width: $tablet) {
      display: none; }
    img {
      width: 100px;
      height: 50px; } }
  .navbarContainer {
    transition: transform 400ms ease-in-out;
    display: flex;
    flex-direction: column;
    position: fixed;
    bottom: 0;
    top: 0;
    right: 0;
    width: 70%;
    padding: 25px;
    align-items: center;
    background-color: $complementary-darkgrey-color;
    @media (min-width: $tablet) {
      position: relative;
      flex-direction: row;
      width: 100%;
      justify-content: space-around;
      background-color: #3e3f3d; }
    .logo {
      width: 130px;
      height: 60px;
      cursor: pointer; }
    .buttonsContainer {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-top: 100px;
      width: 100%;
      @media (min-width: $tablet) {
        margin-top: 0;
        flex-direction: row;
        width: initial; }
      .buttonItem {
        width: 100%;
        border: solid white;
        border-width: 0 0 1px 0;
        color: white;
        background-color: transparent;
        height: 50px;
        font-size: large;
        @media (min-width: $tablet) {
          width: initial;
          cursor: pointer;
          border: none;
          border-radius: 20%;
          margin-right: 20px; } }
      .buttonItem:hover {
        border: 2px solid white; } }
    .handleButton {
      display: block;
      position: fixed;
      top: 15px;
      left: 5%;
      right: 50%;
      @media (min-width: $tablet) {
       display: none; } } }
  .navbarSlideIn {
    transform: translateX(1000px); }
  .navbarSlideOut {
    transform: translateX(0); } }

