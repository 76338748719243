@import "../../styles/colors";
@import "../../styles/typography";
@import "../../styles/responsive";

.footer {
  display: flex;
  color: white;
  flex-direction: column;
  justify-content: center;
  background-color: #3e3f3d;
  padding: 20px;
  h2 {
    color: $secondary-color; }
  .footerContainer {
    display: flex;
    justify-content: space-around;
    @media (max-width: 800px) {
      flex-direction: column;
      align-items: center; }
    img {
      width: 200px;
      height: 100px;
      align-self: center; }
    .list {
      list-style: none;
      @media (max-width: 800px) {
        display: none; }
      .listItem {
        cursor: pointer;
        font-size: 1.5rem;
        margin-bottom: 15px; }
      .listItem:hover {
        border-bottom: 1px solid white; } }
    .contact {
      list-style: none;
      @media (max-width: $mobile) {
        display: none; }
      .contactItem {
        display: flex;
        align-items: center;
        .contactLink {
          margin: 1em;
          color: #fff;
          text-decoration: none; }
        .contactLink:hover {
          color: $primary-color; } } } }
  .copyrightContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 16px;
    p {
      text-align: center;
      a {
        color: white; } }
    .lawsContainer {
      display: flex;
      justify-content: center;
      p {
        text-align: center;
        margin: 2px;
        cursor: pointer; } } } }
