@import "../../styles/colors";
@import "../../styles/typography";
@import "../../styles/responsive";
@import "../../styles/animations";

html, body, #root, #root>div {
  height: 100%; }

.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 100%; }

.forbiddenMessageBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #3b5998;
  padding: 3em;
  .errorCode, .claim, .divider {
    color: #fff; }
  .divider {
    font-size: 4em; }
  .claim {
    margin-bottom: 2em; }

  .returnButton {
    border-color: #fff !important;
    color: #fff !important; } }

.forbiddenBackground {
  background-image: url("../../rsc/img/forbidden-background.jpg");
  background-size: cover;
  background-position: center; }
