@import "../../../../styles/colors";
@import "../../../../styles/typography";
@import "../../../../styles/responsive";

.root {
  top: 0 !important;
  display: flex;
  justify-content: center !important;
  height: 3rem;
  padding-left: 1rem !important;
  padding-right: 1rem !important;

  //background-image: linear-gradient(87deg, $light-gold, $gold-primary 66%)
  //background-image: linear-gradient(87deg, #608cd0, #13c3f2 66%)
  /* fallback for old browsers */
  //background: -webkit-linear-gradient(87deg, #608cd0, #13c3f2 66%) /* Chrome 10-25, Safari 5.1-6 */
  //background: linear-gradient(87deg, #608cd0, #13c3f2 66%) /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  //background: -webkit-linear-gradient(to bottom, #E9E4F0, #D3CCE3) /* Chrome 10-25, Safari 5.1-6 */
 }  //background: linear-gradient(to bottom, #e2c4c6, #e8e1f8) /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

.gridContainerNavBar {
  display: flex;
  align-items: center;
  justify-content: space-between; }

.hideBackButton {
  visibility: hidden !important; }

.gridItem {
  //font-weight: unset
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  max-width: 70%; }

.backIcon {
  //color: #ffffff !important
  font-size: 2em !important; }

.restaurantInfoBox {
  display: flex;
  flex-direction: column !important;
  justify-content: space-around;
  align-items: center !important; }


.restaurantLogoGridItem {
  justify-content: flex-start; }
.restaurantName {
  color: #4f4f4f; }
.restaurantLogo {
  min-height: 3em;
  min-width: 3em;
  max-height: 5em;
  max-width: 100%;
 }  //border-radius: 4px

.formControlSectionSelect {
  min-width: 10em !important;
  .sectionSelectLabel, .sectionSelectLabel:focus {
    color: #ffffff !important; } }
.sectionSelectInput, sectionSelectInput:focus {
  color: #ffffff !important;
  svg {
    color: #ffffff !important; } }


.cartAccessor {
  margin-right: 1.65rem !important; }

.badgeColor {
  color: #fff; }
