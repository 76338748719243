@import "../../../styles/colors";
@import "../../../styles/typography";
@import "../../../styles/responsive";

.restaurantsGridContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8em 2em 4em;
  @media (min-width: $tablet) {
    padding: 8em 4em 4em; } }
