@import "../../../../styles/colors";
@import "../../../../styles/typography";
@import "../../../../styles/responsive";

.expandedPanel {
  box-shadow: 0px 2px 14px 8px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12) !important;
  border-top: 12px solid #5b90d2;
  border-radius: 12px !important; }


.expansionPanelSummary > div {
  align-items: center !important; }

.expansionPanelSummaryExpanded {
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important; }

.dishContainer {
  padding: 0.5rem;
  margin-bottom: 0.5rem; }

.dishCard {
  border-radius: 4px !important;
  position: relative;
 }  //background-color: $gold-primary !important

.productActions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  //background-color: $gold-primary
  color: #fff;

  svg {
    color: #fff; }

  span {
    font-size: 18px; } }

.dishTitle {
  margin-top: 10px;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 12px;
 }  //color: $darker-gold !important
//color: #004d74



.panelSummaryItem {
  display: flex;
  justify-content: center;
  align-items: center; }

.secondaryListItemText {
  font-size: $medium-font-size; }

.dishQuantityBadge {
  background-color: #5c8fd2;
  color: #ffffff; }

.expandIcon {
  //background-color: $third-color
  background-color: #3ba7e1;
  border-radius: 75px;
  color: #ffffff; }

.dishInfoIconContainer {
  min-width: 40px !important; }

.dishInfoIcons {
  color: #479edb; }

.dishPhotoContainer {
  display: flex;
  max-height: 18em;
  justify-content: center;
  @media (min-width: $tablet) {
    justify-content: flex-start; } }

.dishPhotoContainer {
  height: 13rem !important; }

.dishPhoto {
  height: 13rem !important;
  width: 100%;
  @media(min-width: $mobile) {
    object-fit: cover !important; } }



.dishPrice {
  position: absolute !important;
  width: 35px !important;
  height: 35px !important;
  font-size: 12px !important;
  font-weight: 500;
  //background-color: $dark-gold !important
  //color: #fff !important
  top: 5px;
  right: 5px;
  //box-shadow: 0px 2px 14px 8px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12) !important
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.63), 0 2px 7px rgba(0, 0, 0, 0.42) !important; }

.dishQuantityControls {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

.allergenChip {
  margin: 0.6rem;
  background-color: transparent !important;
  font-size: $small-font-size; }

.sectionLinks {
  margin-bottom: 0.5rem !important;
  border-top: 2px; }
