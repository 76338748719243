@import "../../../styles/colors";
@import "../../../styles/typography";
@import "../../../styles/responsive";
@import "../../../styles/animations";


.dialogContent {
  display: flex;
  flex-direction: column;
  justify-content: space-around; }

.closeModalIcon {
  position: absolute !important;
  top: 5px;
  right: 5px; }
