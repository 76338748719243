@import "../../styles/colors";
@import "../../styles/typography";
@import "../../styles/responsive";
@import "../../styles/animations";

.notFound {
  display: flex;
  flex-direction: column;
  margin-top: 150px;
  padding: 4% 20% 4% 20%;
  text-align: justify;
  @media (max-width: $tablet) {
    padding: 4% 20px 4% 20px;
    margin-top: 50px; }
  .numContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    h1 {
      color: $primary-color;
      font-size: 180px;
      @media (max-width: $tablet) {
        font-size: 160px; } }
    img {
      margin-bottom: 35px;
      margin-left: 10px;
      margin-right: -5px;
      height: 100px;
      animation: floatVerticalBracelet 6s ease-in-out infinite;
      //transform: rotate(-90deg)
      @media (max-width: $tablet) {
        height: 90px; } } }

  .comeBackContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h2 {
      color: $complementary-concrete-color; } } }

.navBar {
  background-color: $primary-color !important; }
