@import "../../../../styles/colors";
@import "../../../../styles/typography";
@import "../../../../styles/responsive";

.expansionPanelSummary>div {
  align-items: center !important; }

.dishPhoto {
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
  border-radius: 8px; }

.dishPrice {
  margin-left: auto !important;
  font-weight: bold !important; }

.allergenChip {
  margin: 0.2em;
  background-color: transparent !important; }

.cartItemsContainer {
  min-height: 50vh;
  background-color: rgba(215, 215, 215, 0.38);
  border-radius: 8px;
  padding: 1em 2em 1em 10px !important; }

.removeIcon {
  color: #C62828; }

.dialogContentText {
  font-size: $medium-large-font-size !important; }

.dialogActions {
  margin-right: 1rem !important; }
