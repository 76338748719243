@import "../../styles/colors";
@import "../../styles/typography";
@import "../../styles/responsive";
@import "../../styles/animations";

.mainActions {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  @media(min-width: $tablet) {
    align-items: flex-start !important; }
  .mainActionsButtons {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    @media (min-width: $tablet) {
      align-items: center;
      flex-direction: row; } }
  .mainActionsButtons:nth-child(2) {
      margin-top: 1.5rem; } }


.previewMenusButton, .previewMenusWithImagesButton {
  min-width: 35% !important;
  background-color: #09c6f9 !important;
  color: #fff !important; }
.previewMenusWithImagesButton {
 } // margin-top: 1rem !important
.downloadMenusQrButton {
  background-color: #f9c70d !important;
  color: #fff !important;
  margin-top: 1rem !important;
  @media (min-width: $tablet) {
    margin-top: 0 !important;
    margin-left: 1rem !important; } }

.productsList {
  width: 100% !important;
  border-bottom: 2px solid $primary-color;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px; }

.productAvatar {
  border-radius: 85px;
  height: 40px;
  width: 40px;
  margin-right: 10px;
  object-fit: cover; }

.productActions {
  padding: 6px !important; }
.sectionActions {
  display: flex;
  flex-direction: column; }
.expansionPanelDetails {
  display: flex;
  flex-direction: column;
  align-items: center; }
.addProductButton, .addSectionButton {
  margin-top: 0.5rem !important;
  border-radius: 55px !important;
  width: 10rem; }
.addSectionButton {
  margin-bottom: 1rem !important; }
