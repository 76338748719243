@import "../../styles/colors";
@import "../../styles/typography";
@import "../../styles/responsive";



.parentContainer {
  display: flex;
  justify-content: center;
  height: 100%;

  span {
   font-size: 24px !important; }

  button {
    span {
      font-size: 13px !important; } } }

.restaurantInfoContent {
  display: flex;
  justify-content: center;
  align-items: center; }


.restaurantLogoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1em !important;
  padding: 1rem !important; }


.restaurantLogo {
  height: 80px !important;
  width: 80px !important;
  margin-right: 1em !important;
  border-radius: 50px !important; }


.restaurantName {
  text-align: center; }

.content {
  padding: 0.5em !important; }

.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 25em !important;
  border-radius: 15px !important;
  padding: 1rem !important;
  background-color: $complementary-sand-color !important; }


.chargingContent {
  display: flex;
  justify-content: center; }

.pollSelectorButton {
  position: absolute;
  top: 1em;
  right: 1em; }

.pollSelectorTitle {
  margin-bottom: 1em !important;
  text-align: center !important; }

.pollSelectorCardContent {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center; }

.pollChip {
  margin: 2px !important;
  min-width: 60%;
  background-color: $primary-color !important;
  color: #fff !important;
  padding: 1.5em !important; }

.answerChip {
  margin: 0.5em !important;
  min-width: 225px !important;
  //padding: 1.5em !important
  padding: 0.5em !important;
  border-radius: 50px !important; }

.checkedAnswerIcon {
  position: absolute !important;
  right: 1em !important; }
.numericPicker {
  .picker-inner {
    font-size: 35px !important; }
  .picker-item-selected {
    font-size: 45px !important; } }

.securityPinModalContent {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center; }

.pinInput {
  margin-top: 1em;

  input {
    text-align: center;
    -webkit-text-security: disc !important;
    margin: 4px;
    -moz-appearance: textfield !important;
    -webkit-appearance: none !important;
    width: 15px;
    border-radius: 5px;
    font-size: 18px;
    height: 26px;
    padding: 10px;
    color: #000;
    border: 1px solid lightskyblue; }

  input:focus {
    border: 2px solid #2787fa; }

  /* HIDE INPUT ARROWS TO FIX DESIGN */
  input::-webkit-outer-spin-button,  input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    margin: 0 !important; }

  input[type="number"] {
    -moz-appearance: textfield !important; }
 }  //////////////////////////////////////

.answersContainer {
  display: flex;
  flex-direction: column;
  min-height: 17em;
  align-items: center;
  justify-content: space-evenly; }

.chipsContainer {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap; }

.endPollButton {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  padding: 2em !important;
  margin-top: 1.5em !important; }

.questionTitle {
  font-weight: 500 !important;
  text-align: center !important; }

.invisibleButton {
  visibility: hidden; }

.starSelector {
  font-size: 50px !important;
  color: $primary-color-hover; }

.fullscreenButton {
  position: absolute;
  top: 1em;
  right: 5em; }

.previewModeAlertContainer {
  position: absolute;
  bottom: 5%;
  width: 75%;
  h6, h5 {
    color: #a4a4a4 !important;
    font-weight: bolder !important; } }

.stepperProgress {
  width: 40% !important; }
