@import "../../../styles/colors";
@import "../../../styles/typography";
@import "../../../styles/responsive";
@import "../../../styles/animations";

.featureIcon {
  width: 2em;
  height: 2em; }

.menuCard {
  margin-left: 10px;
  background: rgba($third-color, 0.3) !important;
  min-width: 300px; }

.paperList {
  max-height: 200px;
  overflow: auto; }

