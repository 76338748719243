@import "../../../styles/colors";
@import "../../../styles/typography";
@import "../../../styles/responsive";

.confirmedReservation {
  display: flex;
  color: $primary-color;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 25px;

  .buttonHome {
    align-self: start;
    .homeIcon {
      margin-right: 0.5em; } }
  .circularProgress {
    align-self: center;
    margin: 50px; }
  h1 {
    text-align: center; }
  .form {
    flex-wrap: wrap;
    display: flex;
    .pickersContainer {
      width: 100%;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      @media (max-width: $mobile) {
        flex-direction: column; } } } }
