body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

html, body ,#root, .App{
  min-height: 100%

}

