@import "../../../../styles/colors";
@import "../../../../styles/typography";
@import "../../../../styles/responsive";

.root {
  min-height: 6em !important;
  display: flex;
  justify-content: center;
  //box-shadow: none !important
  background-color: rgb(239, 232, 239) !important;
  /* fallback for old browsers */
  //background: -webkit-linear-gradient(to bottom, #E9E4F0, #D3CCE3) /* Chrome 10-25, Safari 5.1-6 */
 }  //background: linear-gradient(to bottom, #e2c4c6, #e8e1f8) /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

.gridItem {
  display: flex;
  justify-content: center;
  align-items: center; }

.backIcon {
  color: #4f4f4f !important;
  font-size: 2em !important; }

.restaurantInfoBox {
  display: flex;
  flex-direction: column !important;
  justify-content: space-around;
  align-items: center !important; }


.restaurantLogoGridItem {
  justify-content: flex-start; }
.restaurantName {
  color: #4f4f4f; }
.restaurantLogo {
  min-height: 3em;
  min-width: 3em;
  max-height: 5em;
  max-width: 100%;
 }  //border-radius: 4px


.formControlSectionSelect {
  margin-left: 2em !important;
  min-width: 14em !important;
  margin-top: -1.4em !important; }
.sectionSelectInput {
  width: 100% !important; }
