@import "../../../styles/colors";
@import "../../../styles/typography";
@import "../../../styles/responsive";

.confirmedReservationCard {
  width: 100%;
  background-color: #e1e4ff !important;
  border-radius: 25px !important; }

.restaurantCommentItem {
  flex-direction: column;
  align-items: flex-end !important; }

.sendingDataAnimation {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -12px;
  margin-left: -12px; }
