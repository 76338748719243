@import "../../../styles/colors";
@import "../../../styles/typography";
@import "../../../styles/responsive";

.contact {
  display: flex;
  color: white;
  flex-direction: column;
  justify-content: center;
  padding: 25px 0;
  .imgBackground {
    width: 100%;
    position: relative; }
  .claimContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    background-image: url("../../../rsc/img/plate.jpg");
    background-position: center;
    min-height: 500px;
    background-size: cover;
    a {
      color: white; } }
  h1 {
    text-align: center; }
  .form {
    flex-wrap: wrap;
    display: flex; } }
