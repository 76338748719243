.chip {
  margin: 2px 5px; }

.textField {
  margin-left: 10px;
  .contentText {
    border-bottom: 10px; } }

.gridContainer {
  margin: 0px 2.5px !important;
  width: unset !important; }

.parentGrid {
  flex-direction: row;
  flex-wrap: wrap;
  display: flex;
  align-items: baseline;
  border: 1px solid black;
  border-radius: 5px; }

.typography {
  margin-left: 5px !important;
  margin-top: 2px !important; }


