@import "../../styles/colors";
@import "../../styles/typography";
@import "../../styles/responsive";

@keyframes fadeIn {
  0% {
    opacity: 0.25;
    transform: translateY(20px); }

  100% {
    opacity: 1;
    transform: translateY(0); } }

@keyframes fadeOut {
  0% {
    opacity: 1;
    transform: translateY(0); }

  100% {
    opacity: 0;
    transform: translateY(20px);
    display: none; } }


.banner {
  animation: fadeIn 1s ease;
  position: fixed;
  bottom: 0;
  visibility: visible;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 5rem;
  z-index: 1000;
  text-align: center;
  background-color: #ff8b00;
  color: #fff;
  padding: 0.5rem 2.5rem;
  @media(max-width: $tablet) {
    flex-direction: column; }

  .bannerCTA {
    background-color: rgb(9, 185, 247) !important;
    color: #fff !important;
    margin-left: 2rem !important;
    @media(max-width: $tablet) {
      margin-left: unset !important;
      margin-top: 1rem !important; } }
  .bannerCloseIcon {
    position: absolute;
    top: 0;
    right: 0;
    padding: 2px !important;
    svg {
      color: #fff; } } }


.landingManager {
  display: flex;
  color: $primary-color-hover;
  flex-direction: column;
  justify-content: center;
  .content {
    .sectionTitle {
      font-size: $title-font-size;
      text-align: center; }
    p {
      font-size: $large-font-size;
      max-width: 400px; }
    .headerContainer {
      position: relative;
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      background-image: url("../../rsc/img/header-web.jpg");
      background-position: center;
      background-size: cover;
      //border-bottom-left-radius: 25% 20%
      //border-bottom-right-radius: 25% 20%
      @media(min-width: $tablet) {
       // border-bottom-left-radius: 20% 10%
 }       ///border-bottom-right-radius: 20% 10%
      .headerSubcontainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 5rem;
        @media (min-width: $tablet) {
          left: 5rem; } }
      h1 {
        text-align: center;
        max-width: 600px;
        margin-top: 2em;
        @media (min-width: $computer) {
          font-size: $title-font-size; } }
      .buttonsContainer {
        display: flex;
        width: 100%;
        .callToAction {
          background-color: $third-color !important;
          color: white;
          margin: 1em;
          text-decoration: none;
          border-radius: 6px;
          padding: 5px;
          width: 62%;
          max-width: 400px;
          font-size: $large-font-size; }
        .callToAction:hover {
          background-color: $third-color-hover; }
        .callToAction2 {
          background-color: transparentize(#fff, 0.5);
          color: $third-color;
          margin: 1em;
          border: 2px $third-color solid;
          text-decoration: none;
          border-radius: 6px;
          padding: 5px;
          width: 62%;
          max-width: 400px;
          font-size: $large-font-size; }
        .callToAction2:hover {
          background-color: $third-color;
          color: white; } } }

    .customerContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 2rem;
      .customersImg {
        width: 100%;
        @media (min-width: $tablet) {
          width: 50%; } } }
    .featuresSection {
      padding: 2rem;
      display: flex;
      align-items: center;
      flex-direction: column;
      .featuresContainer {
        .card {
          display: flex;
          align-items: center;
          flex-direction: column;
          box-shadow: none;
          .cardLogo {
            height: 5rem;
            width: 5rem;
            background: transparent;
            padding: 4rem;
            border-radius: 3rem; }
          .cardAction {
            display: flex;
            flex-direction: column;
            text-align: center; } } } }
    .reservationContainer {
      padding: 20px;
      @media (min-width: $tablet) {
        display: flex;
        align-items: center; }
      .reservationTextContainer {
        text-align: center;
        min-width: 60%;
        display: flex;
        flex-direction: column;
        align-items: center;
        color: $primary-color; }
      .reservationMockContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        img {
          width: 60%;
          margin-bottom: 32px;
          transform: rotate(15deg);
          z-index: 1;
          @media (min-width: $computer) {
            width: 40%;
            margin-bottom: 0; } }
        .decorationReservation {
          position: absolute;
          left: 0;
          .decorationTriangleTop {
            width: 0;
            height: 0;
            border-left: 50px solid transparent;
            border-right: 50px solid transparent;
            border-bottom: 100px solid transparentize($primary-color, 0.5);
            transform: rotate(10deg); }
          .decorationTriangleRight {
            width: 0;
            height: 0;
            border-top: 50px solid transparent;
            border-left: 100px solid $light-orange;
            border-bottom: 50px solid transparent;
            transform: rotate(-30deg); }
          .decorationTriangleLeft {
            width: 0;
            height: 0;
            border-top: 150px solid transparent;
            border-right: 200px solid $light-blue;
            border-bottom: 150px solid transparent;
            position: absolute; } } } }


    .pollsContainer {
      color: $primary-color;
      padding: 20px;
      @media (min-width: $tablet) {
        display: flex;
        align-items: center;
        flex-direction: row-reverse; }
      .pollsTextContainer {
        text-align: center;
        min-width: 50%;
        display: flex;
        flex-direction: column;
        align-items: center; }
      .pollsMockContainer {
        position: relative;
        img {
          width: 75%;
          margin-bottom: 32px;
          z-index: 1;
          @media (min-width: $tablet) {
            width: 70%;
            margin-bottom: 0; } }
        .mockMobile {
          position: absolute;
          bottom: 0;
          right: 0;
          width: 40%;
          z-index: 1;
          @media (min-width: $tablet) {
            right: 30px;
            width: 30%; } }
        .decorationPolls {
          position: absolute;
          top: 0;
          right: 0;
          z-index: -1;
          .decorationTriangleTop {
            width: 0;
            height: 0;
            border-left: 150px solid transparent;
            border-right: 150px solid transparent;
            border-bottom: 200px solid $light-orange;
            transform: rotate(10deg); }
          .decorationTriangleRight {
            width: 0;
            height: 0;
            border-top: 50px solid transparent;
            border-left: 100px solid $light-blue;
            border-bottom: 50px solid transparent;
            transform: rotate(-30deg);
            position: absolute;
            top: 60%;
            right: 0; }
          .decorationTriangleLeft {
            width: 0;
            height: 0;
            border-top: 50px solid transparent;
            border-right: 100px solid transparentize($third-color, 0.5);
            border-bottom: 50px solid transparent;
            position: absolute;
            right: 10%;
            z-index: 1; } } } }
    .menusContainer {
      padding: 20px;
      color: $primary-color;
      @media (min-width: $tablet) {
        display: flex;
        align-items: center; }
      .menusTextContainer {
        text-align: center;
        min-width: 40%;
        display: flex;
        flex-direction: column;
        align-items: center; }
      .menusMockContainer {
        position: relative;
        img {
          margin-bottom: 32px;
          width: 100%;
          @media (min-width: $tablet) {
            margin-bottom: 0; } }
        .mockDigitalMenuMobile {
          position: absolute;
          right: 0.15rem;
          bottom: 0.25rem;
          width: 18%;
          z-index: 8; }
        .mockDigitalMenuMobileCart {
          position: absolute;
          right: 15%;
          bottom: 0.25rem;
          width: 15%;
          z-index: 3; }
        .mockDigitalMenu {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 50%; }
        .decorationMenus {
          position: absolute;
          top: 0;
          left: -5rem;
          z-index: -1;
          .decorationTriangleTop {
            width: 0;
            height: 0;
            border-left: 150px solid transparent;
            border-right: 150px solid transparent;
            border-bottom: 200px solid $light-blue;
            transform: rotate(10deg); }
          .decorationTriangleRight {
            width: 0;
            height: 0;
            border-top: 50px solid transparent;
            border-left: 100px solid $light-orange;
            border-bottom: 50px solid transparent;
            transform: rotate(-30deg);
            position: absolute;
            top: 60%;
            left: 0; }
          .decorationTriangleLeft {
            width: 0;
            height: 0;
            border-top: 50px solid transparent;
            border-right: 100px solid transparentize($primary-color, 0.5);
            border-bottom: 50px solid transparent;
            position: absolute;
            left: 10%;
            z-index: 1; } } } }
    .priceSection {
      padding: 2rem;
      display: flex;
      align-items: center;
      flex-direction: column;
      .priceContainer {
        .priceCard {
          padding: 0 !important;
          display: flex;
          align-items: center;
          flex-direction: column;
          text-align: center;
          height: 45rem;
          .priceTitleGold {
            position: relative;
            width: 100%;
            padding: 2em;
            color: $primary-color-hover;
            background-color: #fbb034;
            background-image: linear-gradient(315deg, #fbb034 0%, #ffdd00 74%); }
          .priceTitle {
            position: relative;
            width: 100%;
            padding: 2em;
            background-color: #2a2a72;
            background-image: linear-gradient(315deg, #2a2a72 0%, #009ffd 74%);
            color: white;
            clip-path: polygon(0 0,100% 0,100% 100%,0 calc(100% - 3vw));
            svg {
              position: absolute;
              bottom: 0;
              width: 100%;
              height: 5vw; } }
          .cardAction {
            .priceButton {
              background-color: $third-color;
              color: white; } } } } }
    .contactUsSection {
      padding: 2em;
      text-align: center;
      display: flex;
      align-items: center;
      flex-direction: column-reverse;
      @media (min-width: $tablet) {
        flex-direction: row; }
      .contactUsContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        @media (min-width: $tablet) {
          width: 60%; }
        .phoneFieldContainer {
          display: flex;
          align-items: center;
          justify-content: center;
          .submitPhoneButton {
            color: $primary-color-hover !important; } }
        p {
          text-align: center; }
        a {
          color: $third-color-hover;
          font-size: $large-font-size; } }
      .imageContactUs {
        width: 85%;
        @media (min-width: $tablet) {
          width: 50%; } } } }
  .footerContainer {
    color: white;
    background-color: #045de9;
    background-image: linear-gradient(315deg, #045de9 0%, #09c6f9 74%);
    text-align: center;
    border-top-left-radius: 100% 200%;
    border-top-right-radius: 100% 200%;
    padding-top: 1em;
    margin-top: 1em;
    @media (max-width: $tablet) {
      //border-top-left-radius: 100% 200%
      //border-top-right-radius: 100% 200%
      border-radius: 0; }
    a {
      color: $light-orange !important;
      font-weight: bold; }

    .lawsContainer {
      display: flex;
      justify-content: center;
      margin-top: 16px;
      margin-bottom: 16px;
      p {
        text-align: center;
        margin: 2px;
        cursor: pointer; } } } }


.sendingDataAnimation {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12;
  margin-left: -10; }
