@import "../../styles/colors";
@import "../../styles/typography";
@import "../../styles/responsive";

.root {
  display: flex; }

.content {
  margin-top: 3vh;
  padding: 3rem 1.5rem 1.5rem; }

.container {
  justify-content: left;
  overflow-x: hidden; }



.box {
  display: flex !important;
  justify-content: center !important; }

.item {
 }  //min-width: 700px

.card {
 }  //height: 50rem



.graphicsPie {
  min-height: 4vh;
  min-width: 4vh;
  max-height: 40vh;
  max-width: 40vh;
  width: 100%; }

.graphicsArea {
  height: 40vh !important;
  width: 100%;
  margin: 0 auto; }


.button {
  position: sticky !important;
  margin: 0.5rem !important;
  left: 99%; }

.filters {
  position: sticky; }
.labelInfo {
  text-align: center; }

.listItems {
  padding-top: 0.1rem;
  text-align: center;
  color: rgba(0, 0, 0, 0.54) !important; }

.typography {
  text-align: center; }

.textFieldLong {
  margin-right: 20px !important;
  margin-left: 20px !important;
  margin: 0.5rem !important;
  width: 12rem; }
.textFieldShort {
  margin-right: 20px !important;
  margin-left: 20px !important;
  margin: 0.5rem !important;
  width: 6rem; }

.titleContainer {
  margin-top: 1vh;
  display: flex;
  align-items: center;
  padding-bottom: 1rem; }

.cardPieTop {
  //height: 20vh
  display: flex;
  flex-direction: column;
  align-content: center; }
.responsiveContainer {
  @media (max-width: 959px) {
    svg:first-child {
      display: none; } }
  @media (max-width: $tablet) {
    svg:first-child {
      display: none; } } }



.cardPieBot {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100% !important;
  height: 100% !important;
  padding-bottom: 0.5rem; }

.legend {
  padding: 1rem; }
.legendPc {
  padding: 1rem;
  font-size: 12px !important; }

.cardAreaTop {
  position: sticky;
  top: 0; }

.cardTitle {
  align-self: flex-start;
  font-weight: 500 !important;
  margin-top: 0.5rem !important;
  margin-left: 0.5rem !important;
  margin-bottom: 1rem !important;
  position: sticky;
  bottom: 98%; }


.cardArea {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @media(min-width: 1279px) {
    width: 100%;
    height: 100%; } }


.filterTitle {
  padding-bottom: 2rem !important; }

.labelSelect {
  transform: translate(0, 1.5px) scale(0.75); }

.select {
  border: 0;
  display: inline-flex;
  padding: 0;
  position: relative;
  min-width: 0;
  flex-direction: column;
  vertical-align: bottom;
  margin: 0.5rem; }


.questionContainer {
  width: 100%;
  height: 100%; }
