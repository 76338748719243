@import "../../../../styles/colors";
@import "../../../../styles/typography";
@import "../../../../styles/responsive";

.fixGridBox {
  padding: 8px; }

.bodyBox {
  min-height: 300px;
  display: flex;
  background-color: $complementary-darkgrey-color;
  justify-content: center;
  align-items: center;
  padding: 2em;
  border-radius: 15px; }

.bodyBoxLanding {
  min-height: 300px;
  display: flex;
  //background-color: #3e3f3d
  justify-content: center;
  align-items: center;
  padding: 2em; }

.searchBarBox {
  display: flex;
  justify-content: center;
  align-items: center; }

.searchInput {
  background-color: #fff !important;
  border-radius: 15px !important;
  outline: none !important; }

.searchInput:active {
  border-color: dodgerblue !important;
  border-width: 3px !important; }

.clearSearchIcon {
  color: black !important; }

.focusedInput {
  border-radius: 15px !important;
  color: $primary-color-hover;
  outline: none !important;
  border-radius: 15px !important; }

.notch {
  border-radius: 15px !important; }

.searchRestaurantButton {
  border-radius: 15px !important;
  background-color: $primary-color !important;
  color: #fff !important;
  -webkit-box-shadow: 0px 17px 15px -5px rgba(0, 0, 0, 0.75) !important;
  -moz-box-shadow: 0px 17px 15px -5px rgba(0, 0, 0, 0.75) !important;
  box-shadow: 0px 17px 15px -5px rgba(0, 0, 0, 0.75) !important; }

.searchRestaurantButton:hover {
  background-color: $primary-color-hover !important; }

.searchButtonBox {
  display: flex;
  justify-content: center; }

.clearSearchBox {
  display: flex;
  justify-content: center;
  align-items: center; }

.clearSearchButtonIcon {
  color: wheat !important;
  margin-right: 5px; }

.clearSearchButton {
  color: wheat !important; }

.noFiltersText {
  color: red !important;
  font-style: italic; }
