@import "../../../styles/colors";
@import "../../../styles/typography";
@import "../../../styles/responsive";

.reservation {
  display: flex;
  color: $primary-color;
  flex-direction: column;
  justify-content: center;
  padding: 8em 2em 2em;

  .buttonBack {
    align-self: start;
    margin-top: 2em;
    margin-left: 3.55em;

    @media (min-width: $tablet) {
      margin-left: 7em; }


    .buttonBackIcon {
      margin-right: 0.5em; } }

  .circularProgress {
    align-self: center;
    margin: 50px; }

  h1 {
    text-align: center; }

  .formContainer {
    margin: 1em 2em;
    @media (min-width: $tablet) {
      margin: 1em 7em; }
    h1 {
      margin-bottom: 2em; }
    .reservationButton {
      margin-top: 3em; }
    .form {
      flex-wrap: wrap;
      display: flex;

      .pickersContainer {
        width: 100%;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        @media (max-width: $mobile) {
          flex-direction: column; } } } } }
