@import "../../styles/colors";
@import "../../styles/typography";
@import "../../styles/responsive";

.root {
  flex-grow: 1;
  width: 100%;
  position: fixed;
  z-index: 10; }
.drawerContainer {
  transition: transform 400ms ease-in-out;
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: 0;
  top: 0;
  right: 0;
  width: 70%;
  padding: 25px;
  //background-color: transparentize($light-blue, 0.03)
  background-color: #a9e8ff;
  color: $primary-color-hover;
  font-size: 2rem;
  box-shadow: 0 2px 14px 8px rgba(0,0,0,.2),0 1px 1px 0 rgba(0,0,0,.14),0 1px 3px 0 rgba(0,0,0,.12);
  @media (min-width: $tablet) {
    display: none; }
  .handleButton {
    margin-bottom: 2rem;
    svg {
      font-size: 2rem; } }
  .nestedList {
    margin-top: 1rem;
    text-align: center;
    svg {
      font-size: 2rem;
      color: $primary-color-hover; }
    a {
      color: $primary-color-hover;
      text-decoration: none; } }
  .decoration {
    position: absolute;
    right: 0;
    bottom: 0;
    .decorationTriangleTop {
      width: 0;
      height: 0;
      border-left: 50px solid transparent;
      border-right: 50px solid transparent;
      border-bottom: 100px solid transparentize($primary-color, 0.5);
      transform: rotate(10deg); }
    .decorationTriangleRight {
      width: 0;
      height: 0;
      border-top: 50px solid transparent;
      border-left: 100px solid $light-orange;
      border-bottom: 50px solid transparent;
      transform: rotate(-30deg); }
    .decorationTriangleLeft {
      width: 0;
      height: 0;
      border-top: 150px solid transparent;
      border-right: 200px solid transparentize($third-color, 0.5);
      border-bottom: 150px solid transparent;
      position: absolute; } } }

.drawerSlideIn {
  transform: translateX(1000px); }
.drawerSlideOut {
  transform: translateX(0); }

.flex {
  flex: 1; }
.toolbar {
  display: flex;
  justify-content: space-between;
  //background-color: $secondary-color
  background-image: linear-gradient(315deg, #045de9 0%, #09c6f9 74%);

  .logo {
    width: 100px;
    cursor: pointer; }
  .menuButton {
    color: white !important;
    margin-right: 0.5rem !important;
    @media (min-width: $tablet) {
      display: none !important; } }
  .buttonsContainer {
    display: flex;
    align-items: center;
    @media (max-width: $tablet) {
      display: none !important; } }
  .openLoginModalBt {
    background-color: $third-color !important;
    color: white !important; }
  .sectionButtonsContainer {
    display: flex;
    align-items: center;
    margin-right: 3rem;
    .sectionBt {
      color: #ffffff;
      margin: 0 1rem; } } }
.circularProgress {
  top: 50%;
  left: 50%;
  margin-top: -12;
  margin-left: -12; }
.submit {
  margin-top: 20px * 3; }

.asotiation {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 10px; }

.errorBox {
  margin-top: 10px; }

.promoHurrify {
  background-color: #c0ceff42;
  padding: 1rem;
  margin-bottom: 1rem;
  text-align: center;
  color: #004a6f;
  border-top: 6px solid #004a6f;
  border-radius: 0 0 8px 8px; }

.closeModalIcon {
  position: absolute !important;
  top: 2px;
  right: 2px; }
