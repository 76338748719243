@import "../../../styles/colors";
@import "../../../styles/typography";
@import "../../../styles/responsive";

.detailRestaurant {
  display: flex;
  color: $primary-color;
  flex-direction: column;
  justify-content: center;
  background-color: white;
  padding: 8em 2em 1em;


  .circularProgress {
    align-self: center;
    margin: 50px; }

  .restaurantCard {
    min-height: 500px;
    box-shadow: none;
    padding: 16px 0;

    .avatar {
      width: 100px;
      height: 100px; }

    .img {
      height: 300px;
      border-radius: 5px;
      margin: 5px; }

    .imagesTitleContainer {
      display: flex;
      margin: 10px;
      align-items: center;

      h3 {
        margin-left: 5px; } }

    .buttonReserve, .disabledButtonReserve {
      margin-top: 3em;
      border: 1px solid; }
    .disabledButtonReserveBox {
      display: flex;
      flex-direction: column;
      .disabledButtonReserve {
        margin-bottom: 1em; } }

    .slickPrev {
      cursor: pointer;
      position: absolute;
      left: 3% !important;
      z-index: 1;
      top: 50%;
      color: $primary-color;
      border: 1px solid;
      border-radius: 6px;
      background-color: white; }

    .slickNext {
      cursor: pointer;
      position: absolute;
      right: 3% !important;
      z-index: 1;
      top: 50%;
      color: $primary-color;
      border: 1px solid;
      border-radius: 6px;
      background-color: white; }

    .scheduleContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 16px;
      font-size: $tiny-font-size;
      @media (min-width: $tablet) {
        font-size: $medium-font-size;
        align-items: flex-end; }

      .scheduleTable {
        td {
          text-align: center; } }

      .allDayCell {
        color: #fff;
        border-radius: 5px;
        background-color: rgba(0, 119, 181, 0.59); } } } }


.dataContainer {
  display: flex;
  justify-content: space-between;
  .dataInfoContainer {
    margin: 10px; } }
