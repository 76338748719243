@import "../../../styles/colors";
@import "../../../styles/typography";
@import "../../../styles/responsive";

.parentContainer {
  overflow: hidden !important; }

.title {
  position: relative;
  top: 10%;
  width: 80%;
  text-align: right; }


.imgContainer {
  display: flex;
  justify-content: space-around;
  padding: 50px;
  background: #eef1f5;

  .img {
    height: 20%;
    width: 20%; } }

.section {
  margin: 50px;
  text-align: center; }

.searchParentContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  //height: -webkit-fill-available
  background-color: $complementary-darkgrey-color;
  background-image: url("../../../rsc/img/fondo-landing.webp");
  background-size: cover;

  .searchBox {
    // padding-top: 4em
    height: fit-content; }

  .searchClaim {
    width: 70%;
    margin-top: 10em;
    text-align: center;
    align-self: center;
    background-color: rgba(0, 119, 181, 0.56);
    border-radius: 15px;
    padding: 35px;
    -webkit-box-shadow: 0px 18px 40px -8px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 18px 40px -8px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 18px 40px -8px rgba(0, 0, 0, 0.75);
    h2 {
      color: #fff;
      font-size: $large-font-size;
      @media (min-width: $tablet) {
        font-size: $title-font-size; } } } }


.restaurantsGrid {
  margin-bottom: 2em !important;
  padding: 45px !important;
  h2 {
    text-align: center;
    @media (max-width: $tablet) {
      font-size: $big-font-size;
      margin-bottom: 0.5em; } }
  .ourFavorites {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 1em !important;
    @media (max-width: $mobile) {
      flex-direction: column; } }
  .hiddenMobileCard {
    @media (max-width: 960px) {
      display: none; } } }



.featuresParent {
  display: flex !important;
  background-color: $primary-color;
  color: #fff;
  padding-bottom: 0;

  .featuresContainer {
    display: flex !important;
    margin: 2em !important;
    justify-content: center !important;

    .featuresSubtitle {
      display: flex;
      justify-content: center;
      margin-bottom: 4em;
      @media (max-width: $tablet) {
        margin-bottom: 0.5em; }

      h2 {
        color: #fff !important;
        text-align: center;
        @media (max-width: $tablet) {
          font-size: 3em; } } }

    .featureBox {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      text-align: center;
      @media (max-width: $tablet) {
        margin-bottom: 2em; }

      .featureIcon {
        font-size: 7em;
        color: #fff;
        margin-bottom: 0.25em; }
      h5 {
        color: #fff; } } } }

.contactParentContainer {
  background-color: $complementary-darkgrey-color;
  color: #fff;
  padding-top: 4em;
  padding-bottom: 4em;
  background-image: url("../../../rsc/img/contact-background.webp");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  .contactContainer {
    display: flex !important;
    flex-direction: column;
    margin: 2em !important;
    justify-content: center !important;
    h5, h6 {
      color: #fff; }
    .contactItem {
      display: flex !important;
      flex-direction: column;
      justify-content: center !important;
      text-align: center;
      .contactButton {
        padding: 1em 6em;
        align-self: center;
        color: #fff !important;
        border-color: $primary-color; } } } }
