@import "../../../../styles/colors";
@import "../../../../styles/typography";
@import "../../../../styles/responsive";

.expansionPanelSummary>div {
  align-items: center !important; }

.dishPhoto {
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
  border-radius: 8px; }

.dishPrice {
  margin-left: auto !important;
  font-weight: bold !important; }

.allergenChip {
  margin: 0.2em;
  background-color: transparent !important; }

.cartItemsContainer {
  min-height: 50vh;
  //background-color: rgba(215, 215, 215, 0.38)
  border-radius: 8px;
 }  //padding: 1em 2em 1em 10px !important


.removeIconButton {
  position: absolute !important;
  top: 0;
  right: 0; }




.dialogContentText {
 }  //font-size: $medium-large-font-size !important


.listItemCard {
  position: relative;
  display: flex;
  justify-content: center;
  padding: 0.5rem 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  //background: linear-gradient(87deg, #aceeff, #6cdaf8 66%)

  .cartItemActions {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column-reverse;
    background: #ffff;
    //border: 1px solid $darker-gold
    border-radius: 8px;
    margin-right: 1rem;
    svg {
 } }      //color: $darker-gold

  .cartItemTitle {
    //color: $darker-gold !important
    max-width: 90% !important; } }


.dialogActions {
  justify-content: center !important;
  align-items: unset !important;
  flex-direction: column;
  box-shadow: 0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12); }

.checkoutButtonContainer {
  padding-right: 2.5rem;
  padding-left: 2.5rem;
 }  //margin-top: 4rem !important
.checkoutButton {
  //background: linear-gradient(87deg, $light-gold, $gold-primary 66%)
  color: #ffffff !important;
  border-radius: 12px !important; }
.checkoutButton:disabled {
  background: unset; }

.confirmButton {
  //background: linear-gradient(87deg, $gold-primary, $dark-gold 66%)
  //color: #ffffff !important
  border-radius: 12px !important; }
