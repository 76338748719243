@import "../../styles/colors";
@import "../../styles/typography";
@import "../../styles/responsive";
@import "../../styles/animations";

.parentContainer {
  margin-top: 15px !important;
  margin-bottom: 3em !important; }
.contentCard {
  background-color: #f3f7f8 !important;
  margin-top: 1rem; }

.closeModalIcon {
  position: absolute !important;
  right: 0.4em !important; }

.progressAnimation {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12px;
  margin-left: -12px; }

.expansionPanelSummaryContent {
  display: flex;
  align-items: center; }

.expansionPanelDetails {
  display: flex;
  flex-direction: column;
  align-items: center; }

.timeSpendClaim {
  text-align: center; }

.datePickerContainer {
  margin-top: 1em !important;
  margin-bottom: 1em !important;
  display: flex !important; }

.datePicker {
  display: flex;
  align-items: center;
  justify-content: space-evenly; }

.datePickerActions {
  display: flex;
  justify-content: flex-end; }

.questionsStatisticsContainer {
  margin-top: 2em !important; }

.statisticsQuestionTitle {
  display: flex;
  align-items: center; }

.nestedStatisticsItem {
  padding-left: 2em !important; }

.fixListItemTextPadding {
    padding: 0 !important; }

.answersStatisticsContainer {
  justify-content: center; }

.answersStatisticsRateContainer {
  justify-content: space-around;
  .answerStatisticsItem {
    border-radius: 25px; } }

.answerStatisticsItem {
  display: flex;
  justify-content: center;
  text-align: center;
  background: #e7e4ff; }

.answerStatisticsTotalVotesItem {
  border-radius: 25px;
  text-align: center;
  margin-top: 2em !important;
  background: #e7e4ff; }

.answerStatisticsItemYes {
  background-color: #9eff70;
  border-top-left-radius: 25px;
  text-align: center; }


.answerStatisticsItemNo {
  background-color: #ff6258;
  border-top-right-radius: 25px;
  text-align: center; }


.answerStatisticsItemChip {
    background: #e7e4ff;
    text-align: center; }
.answerStatisticsItemFirst {
  background: #e7e4ff;
  border-top-left-radius: 25px;
  text-align: center; }


.answerStatisticsItemSecond {
  background: #e7e4ff;
  border-top-right-radius: 25px;
  text-align: center; }

.averageRateText {
  display: flex;
  align-items: center;
  justify-content: center; }
.averageRateIcon {
  font-size: 15px !important;
  margin-right: 0.25em; }

.numericQuestionIcon, .booleanQuestionIcon {
  width: 18px;
  height: 18px;
  border-radius: 3px;
  margin-left: 2.9px; }

.numericQuestionStatisticsIcon, .booleanQuestionStatisticsIcon {
  width: 18px;
  border-radius: 3px;
  margin-right: 0.6em; }

.questionTitleIcon {
  margin-right: 0.25em; }

.downloadAnswersContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 3em!important; }
