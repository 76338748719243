@import "../../styles/colors";
@import "../../styles/typography";

.success {
  div {
    background-color: $snack-success;
    font-weight: 400; } }


.warning {
  div {
    background-color: $snack-warning;
    font-weight: 400; } }
.error {
  div {
    background-color: $snack-error;
    font-weight: 400; } }
.snackButton {
  color: #fff !important;
  border-color: #fff !important; }

.snackRoot {
  z-index: 9999999 !important; }

