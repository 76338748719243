@import "../../../../styles/colors";
@import "../../../../styles/typography";
@import "../../../../styles/responsive";

.mainContainer {
  padding-bottom: 2rem; }
.productImage {
  width: 100%;
  object-fit: cover;
  height: 23rem;
  margin-top: -0.5rem; }
.productPrice {
  position: absolute;
  border-radius: 85px;
  left: 50%;
  bottom: -1rem;
  transform: translateX(-50%);
  padding: 0.5rem 1.5rem;
  font-size: 22px !important;
  font-weight: 500;
  //color: $darker-gold !important
  //background-color: #fff !important
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.63), 0 2px 7px rgba(0, 0, 0, 0.42) !important; }

.productDetails {
  margin-top: 1rem !important; }

.secondaryListItemText {
  font-size: $medium-font-size; }
.dishQuantityBadge {
  //background-color: #fff
 }  //color: $darker-gold
.dishInfoIconContainer {
  min-width: 40px !important; }
.dishInfoIcons {
  color: $dark-gold; }
.dishPhoto {
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
  border-radius: 8px; }

.dishPrice {
  margin-left: auto !important;
  font-weight: bold !important;
  margin-right: 0.75em !important; }

.dishQuantityControls {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

.allergenChip {
  margin: 0.6rem;
  background-color: transparent !important;
  font-size: $small-font-size; }

.sectionLinks {
  margin-bottom: 5rem !important;
  border-bottom: 2px solid #459fdc; }

.productActions {
  position: fixed;
  bottom: 0.5rem;
  left: 50%;
  transform: translate(-50%, 0);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  border-radius: 75px;
  //background-color: $dark-gold !important
  color: #fff !important;
  svg {
    color: #fff; } }
