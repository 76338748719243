@import "../../../styles/colors";
@import "../../../styles/typography";
@import "../../../styles/responsive";
@import "../../../styles/animations";

.fullDisplayFlex {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  @media (min-width: $tablet) {
    align-items: center; } }

.displayFlexCenter {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 8px;
  @media (min-width: $tablet) {
    align-items: center;
    flex-direction: row;
    margin-top: 0; } }

.menuPrice {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  height: 30px;
  min-width: 70px;
  border-radius: 45px;
  background-color: #d3d3d3;
  @media(min-width: $tablet) {
    margin-left: 1rem; } }

.stateSwitchAndPriceContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  @media (min-width: $tablet) {
    align-items: center;
    flex-direction: row; } }
