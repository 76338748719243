@import "../../styles/colors";
@import "../../styles/typography";
@import "../../styles/responsive";
html * {
  font-family: Montserrat, Rubik, sans-serif !important; }

.container {
  width: 100% !important;
  justify-content: center; }

.mainSkeleton {
  //height: 100vh !important
  width: 100%; }

.gridGlobalContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  overflow: hidden;
  padding-bottom: 1em; }

.parentContainer {}



.content {
  width: 100%;
  padding-top: 3.5rem;
  @media (min-width: $tablet) {
    width: 65%; } }
.contentNotFound {
  width: 100%; }
.restaurantInfoContent {
  display: flex;
  justify-content: center;
  align-items: center; }

.restaurantLogoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1em !important; }

.restaurantLogoHeader {
  width: 100%;
  object-fit: cover;
  max-height: 15rem;
  margin-top: -1rem; }


.restaurantLogo {
  height: 80px !important;
  width: 80px !important;
  border-radius: 12px !important;
  margin: 0 auto; }

.restaurantName {
  text-align: center;
  background: #161616;
  color: #fff;
  border-bottom-left-radius: 80px;
  border-bottom-right-radius: 80px;
  font-weight: 500 !important;
  position: fixed;
  width: 75%;
  left: 50%;
  transform: translate(-50%, 0);
  font-family: "Roboto", sans-serif !important;
  min-height: 1.85rem;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23); }

.menusList {
  padding-left: 1rem !important;
  padding-right: 1rem !important; }
.menuListItem {
  height: 5rem;
  //background-color: $gold-primary !important
  color: #fff !important; }
.goToMenuIcon {
  background-color: #fff !important;
  color: red !important;
  border-radius: 75px; }

.sectionContainer {
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  .sectionCard {
    border-radius: 4px !important;
    background-color: #c9c9c9 !important; }
  .sectionImage {
    height: 14rem;
    width: 100%;
    object-fit: cover;
    border-radius: 4px; }
  .sectionTitle {
    margin-top: 10px;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 12px;
    //color: $darker-gold
 } }    //color: #004d74

.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 25em !important;
  border-radius: 15px !important;
  padding-top: 2em !important;
  background-color: $complementary-sand-color !important; }


.chargingContent {
  display: flex;
  justify-content: center;
  margin-top: 3rem; }

.menuSelectorButton {
  position: fixed;
  z-index: 10000;
  top: 0.2em;
  right: 0.2em; }


.menuSelectorTitle {
  margin-bottom: 1em !important;
  text-align: center !important; }

.menuSelectorCardContent {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center; }

.menuChip {
  margin: 0.7em;
  min-width: 60%;
  background-color: $primary-color !important;
  color: #fff !important;
  padding: 1.5em !important; }


.securityPinModalContent {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center; }

.pinInput {
  margin-top: 1em;

  input {
    text-align: center;
    -webkit-text-security: disc !important;
    margin: 4px;
    -moz-appearance: textfield !important;
    -webkit-appearance: none !important;
    width: 15px;
    border-radius: 5px;
    font-size: 18px;
    height: 26px;
    padding: 10px;
    color: #000;
    border: 1px solid lightskyblue; }

  input:focus {
    border: 2px solid #2787fa; }

  /* HIDE INPUT ARROWS TO FIX DESIGN */
  input::-webkit-outer-spin-button,  input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    margin: 0 !important; }

  input[type="number"] {
    -moz-appearance: textfield !important; } }
//////////////////////////////////////

.invisibleButton {
  visibility: hidden; }

.dialogContentText {
  font-size: $medium-large-font-size !important; }
