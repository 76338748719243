@import "../../../styles/colors";
@import "../../../styles/typography";
@import "../../../styles/responsive";
@import "../../../styles/animations";

.root {
  margin: 1rem;
  min-height: 93%;
  position: relative; }
.buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-content: flex-end;
  position: absolute;
  right: 0;
  bottom: 0;
  margin: 1rem; }

.color {
  width: 36px;
  height: 14px;
  border-radius: 2px; }
//background: `rgba(${ this.state.color.r }, ${ this.state.color.g }, ${ this.state.color.b }, ${ this.state.color.a })`,

.swatch {
  padding: 5px;
  background: #fff;
  border-radius: 1px;
  box-shadow: 0 0 0 1px rgba(0,0,0,.1);
  display: inline-block;
  cursor: pointer; }

.popover {
  position: absolute;
  z-index: 2; }

.cover {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }
.cardContent {
  display: flex; }
.item {
  padding: 1em;
  display: flex;
  align-items: center; }
.sendingDataAnimation {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12px !important;
  margin-left: -10px !important; }

.pickerContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 2rem;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 14px 28px rgba(0,0,0,0.29), 0 10px 10px rgba(0,0,0,0.26); }

.shadowFix {
  div>div {
    box-shadow: none !important; } }

.compactPicker {
  box-shadow: none;
  background: #ffffff;
  border: 1px solid #cfcfcf;
  border-radius: 8px; }

.huePicker {
  margin-top: 1rem; }
