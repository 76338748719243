@import "../../../styles/colors";
@import "../../../styles/typography";
@import "../../../styles/responsive";
@import "../../../styles/animations";


.dialogContent {
  display: flex;
  flex-direction: column;
  justify-content: space-around; }

.currentProductImageContainer {
  position: relative;
  height: 15rem; }

.currentProductImage {
  height: 100%;
  object-fit: cover;
  width: 100%; }
.editDishImageButton {
  background-color: $primary-color !important;
  position: absolute !important;
  bottom: 5px !important;
  right: 5px !important;
  svg {
    color: #fff; } }

.closeModalIcon {
  position: absolute !important;
  top: 5px;
  right: 5px; }
